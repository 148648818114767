import axios from '@/http';

/**
 * 销售网络
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/website-service' : '';
const url_prefix = cloud + '/api/public/v1/salesperson';


/**
 * 获取（单个）
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSalespersonV1Api = (id) => axios.get(url_prefix + "/get?id=" + id);

/**
 * 获取（列表）
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listSalespersonV1Api = () => axios.post(url_prefix + "/list");