import axios from '@/http';

/**
 * 留言
 * @type {string}
 */
const cloud = (process.env.VUE_APP_API_CLOUD == 'cloud') ? '/message-service' : '';
const url_prefix = cloud + '/api/public/v1/message';

/**
 * 添加
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addMessageV1Api = (data) => axios.post(url_prefix + "/add", data);