<template>
    <div id="nt_wrapper">

        <Header/>

        <div id="nt_content">

            <!--hero banner-->
            <div class="kalles-section page_section_heading">
                <div class="page-head tc pr oh cat_bg_img page_head_">
                    <div class="parallax-inner nt_parallax_false lazyload nt_bg_lz pa t__0 l__0 r__0 b__0" data-bgset="/img/banner-009.jpeg"></div>
                    <div class="container pr z_100">
                        <h1 class="mb__5 cw">{{options.title}}</h1>
                        <p class="mg__0" v-html="options.description"></p>
                    </div>
                </div>
            </div>
            <!--end hero banner-->

            <!--page content-->
            <div class="kalles-section container mb__50 cb">
                <div class="row fl_center">
                    <div class="contact-form col-12 col-md-6 order-1 order-md-0">
                        <form method="post" action="#" class="contact-form">
                            <h3 class="mb__20 mt__40">给我们留言</h3>
                            <p>
                                <label for="name">您的名字 (*)</label>
                                <input type="text" v-model="options.message.name" id="name" name="name" required />
                            </p>
                            <p>
                                <label for="phone">您的电话 (*)</label>
                                <input type="tel" v-model="options.message.phone" id="phone" name="phone" pattern="[0-9\-]*" required />
                            </p>
                            <p>
                                <label for="email">您的邮箱</label>
                                <input type="email" v-model="options.message.email" id="email" name="email" />
                            </p>
                            <p>
                                <label for="content">留言信息 (*)</label>
                                <textarea rows="20" v-model="options.message.content" id="content" name="content" required></textarea>
                            </p>
                            <div id="your-dom-id" class="nc-container" style="margin-bottom: 1.3em;"></div>
                            <input type="submit" @click.prevent="clickSubmit()" class="button w__100" value="提交">
                        </form>
                    </div>
                    <div class="contact-content col-12 col-md-6 order-0 order-md-1">
                        <h3 class="mb__20 mt__40">联系方式</h3>
                        <p>我们很乐意在我们的客户服务、商品、网站或任何您想与我们分享的话题上听到您的意见。欢迎您的意见和建议。请填写以下表格。</p>
                        <p class="mb__5 d-flex"><i class="las la-home fs__20 mr__10 text-primary"></i> {{website.province}}{{website.city}}{{website.district}}{{website.town}}{{website.address}}</p>
                        <p class="mb__5 d-flex"><i class="las la-phone fs__20 mr__10 text-primary"></i> {{website.hotline}} / {{website.telephone}}</p>
                        <p class="mb__5 d-flex"><i class="las la-envelope fs__20 mr__10 text-primary"></i> {{website.email}}</p>
                        <p class="mb__5 d-flex"><i class="las la-clock fs__20 mr__10 text-primary"></i> {{website.working_hours}}</p>

                        <div class="kalles-term-exp mt__40">
                            <h3 class="mg__0 mb-0">其他销售网络</h3>
                            <div class="row">
                                <div v-for="(dt, index) in salesperson_list" :key="index" class="col-12 col-md-6">
                                    <div class="store-local mt__20">
                                        <h4 class="mt-0 mb-2">{{dt.area}}</h4>
                                        <p class="mb-1">联系人：{{dt.name}}</p>
                                        <p class="mb-1" v-if="dt.phone">电话：{{dt.phone}}</p>
                                        <p class="mb-1" v-if="dt.wechat">微信：{{dt.wechat}}</p>
                                        <p class="mb-1" v-if="dt.qq">QQ：{{dt.qq}}</p>
                                        <p class="mb-1" v-if="dt.email">邮箱：{{dt.email}}</p>
                                        <p class="mb-1" v-if="dt.working_hours">工作时间：{{dt.working_hours}}</p>
                                        <p class="mb-0" v-if="dt.province || dt.address">地址：{{dt.province}}{{dt.city}}{{dt.district}}{{dt.town}}{{dt.address}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end page content-->

        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import {listSalespersonV1Api} from "@/api/website/salesperson";
import {addMessageV1Api} from "@/api/about/contact";

export default {
    name: 'Contact',
    components: {Header, Footer},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '联系我们',
            description: '',
            name: proxy.$route.name,
            url: proxy.$route.path,
            message: {
                name: "",
                email: "",
                phone: "",
                content: "",
                nc_token: "",
                csessionid: "",
                sig: "",
            },
        });



        const salesperson_list = ref([]);
        const apiSalespersonList = () => {
            return new Promise(function (resolve) {
                listSalespersonV1Api().then(res => {
                    if (res.data.code == 0) {
                        salesperson_list.value = res.data.data;
                    }

                    resolve(true);
                });
            });
        };

        // 手机座机同时验证
        const checkPhoneTel = (tel) => {
            if(!(/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(tel))) {
                return false;
            } else {
                return true;
            }
        }

        // // 座机验证
        // const checkTel = (tel) => {
        //     if(!(/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(tel))) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }

        // 手机号码
        // const checkPhone = (phone) => {
        //     if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|16[6]|19[89]]|17[01345678]|18[0-9]|14[579])[0-9]{8}$/.test(phone))) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }

        // 邮箱
        const checkEmail = (email) => {
            if(!(/^\w+@[a-z0-9]+\.[a-z]{2,4}$/.test(email))) {
                return false;
            } else {
                return true;
            }
        }

        const clickSubmit = () => {
            if (!options.message.name || !options.message.content || !options.message.phone || !options.message.email) {
                if (!options.message.name) {
                    document.getElementById('name').focus();

                    alert('名字不能为空');
                } else if (!options.message.phone) {
                    document.getElementById('phone').focus();

                    alert('联系方式不能为空');
                } else if (!options.message.email) {
                    document.getElementById('email').focus();

                    alert('邮箱不能为空');
                } else if (!options.message.content) {
                    document.getElementById('content').focus();

                    alert('留言信息不能为空');
                }
                return;
            } else if (options.message.phone && !checkPhoneTel(options.message.phone)) {
                document.getElementById('phone').focus();

                alert('填写正确的联系方式');

                return;
            } else if (options.message.email && !checkEmail(options.message.email)) {
                document.getElementById('email').focus();

                alert('填写正确的邮箱');

                return;
            } else if (!options.message.nc_token || !options.message.csessionid || !options.message.sig) {

                alert('拖动验证');

                return;
            }

            addMessageV1Api(options.message).then(res => {
                if (res.data.code == 0) {
                    alert('我们已经收到您的留言，会第一时间联系。');

                    location.reload();
                } else {
                    alert(res.data.msg);
                }
            });
        }

        // 初始数据加载
        Promise.all([apiSalespersonList()]).then(() => {
            // apiProductList().then(() => {
            //     mainNotyConfirm.close();
            // });
        });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-product-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            const nc_token = ["FFFF0000000001790A0F", (new Date()).getTime(), Math.random()].join(':');
            const NC_Opt = {
                renderTo: "#your-dom-id",
                appkey: "FFFF0000000001790A0F",
                scene: "nc_other",
                token: nc_token,
                customWidth: 300,
                trans:{"key1":"code0"},
                elementID: ["usernameID"],
                is_Opt: 0,
                language: "cn",
                isEnabled: true,
                timeout: 3000,
                times:5,
                apimap: {
                    // 'analyze': '//a.com/nocaptcha/analyze.jsonp',
                    // 'get_captcha': '//b.com/get_captcha/ver3',
                    // 'get_captcha': '//pin3.aliyun.com/get_captcha/ver3'
                    // 'get_img': '//c.com/get_img',
                    // 'checkcode': '//d.com/captcha/checkcode.jsonp',
                    // 'umid_Url': '//e.com/security/umscript/3.2.1/um.js',
                    // 'uab_Url': '//aeu.alicdn.com/js/uac/909.js',
                    // 'umid_serUrl': 'https://g.com/service/um.json'
                },
                callback: function (data) {
                    options.message.nc_token = nc_token;
                    options.message.csessionid = data.csessionid;
                    options.message.sig = data.sig;

                    // window.console && console.log(nc_token)
                    // window.console && console.log(data.csessionid)
                    // window.console && console.log(data.sig)
                }
            }

            // eslint-disable-next-line no-undef
            const nc = new noCaptcha(NC_Opt)
            nc.upLang('cn', {
                _startTEXT: "请按住滑块，拖动到最右边",
                _yesTEXT: "验证通过",
                _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>",
            })

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {options, website, salesperson_list, clickSubmit}
    },
}
</script>

<style scoped>
.swatch_style_rounded .swatch__list .swatch__list--item, .swatch_style_rounded .swatch__list .swatch__value {
    border-radius: 0;
}
</style>